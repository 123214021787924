<template>
<div class="review">
  <div class="common-bg padding-50">
    <div class="container">
      <h1>活動回顧</h1>

      <!--  v-viewer可以自定义一些Options是否显示,false是不显示    -->
      <div class="images" v-viewer="{movable: false,
      navbar: false, title: false, tooltip: false,
      movable: false, zoomable: false, rotatable: false,
      scalable:false}">
            <img v-for="src in list" :key="src" :src="src.image" class="image-location">
        </div>
      </div>

    <loading-list ref="changeView" class="pdf-page"></loading-list>


 </div>
</div>
</template>

<script>
import loadingList from "@/components/loadingList";


import {getDetail} from "@/network/home";

import { defineComponent } from 'vue';

export default  defineComponent ({
  name: "Review",
  components:{
    loadingList,
  },
  data() {
    return{
      id:null,
      list: [],
      page: 1, //当前页数
      pageSize: 6, //每页10条
     }
  },
  created() {
    this.getDetail()
  },
  mounted() {
    window.addEventListener("scroll", this.isRefresh, true);
  },

  methods:{
    isRefresh() {
      let scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
      let windowHeight =
          document.documentElement.clientHeight || document.body.clientHeight;
      let scrollHeight =
          document.documentElement.scrollHeight || document.body.scrollHeight;
      if (
          scrollTop + windowHeight >= scrollHeight - 560 &&
          this.isRefreshBool
      ) {
        this.isRefreshBool = false;
        this.getDetail();
      }
    },

    getDetail(){
      this.id = this.$route.params.id; //拿到id
      getDetail(this.id).then(res => {

        // console.log(res.data.data.review);

        let begin = (this.page - 1) * this.pageSize;
        let end = this.page * this.pageSize;
        let data = [];
        data = [...this.list, ...res.data.data.review.slice(begin, end)];
        this.list = data


        ///
        this.$refs.changeView.noMore = false;
        if (this.list.length === res.data.data.review.length) {
          this.$refs.changeView.noMore = true;
          this.isRefreshBool = false;
         }else {
          this.$refs.changeView.isRefreshBool = true;
          setTimeout(() => {
            this.page++;
            this.getDetail();
            this.$refs.changeView.loading = false;
          }, 1000);
        }





    })
    }
  }
})


</script>

<style>



.review{
  padding-top: 92px;
}

.review h1{
  padding-bottom: 40px;
}

.image-location{
  width: calc(33% - 10px);
  cursor: pointer;
  margin:20px 5px;
  display: inline-block;
}
.viewer-play, .viewer-reset{
  display: none;
}

/* v-viewer图片的样式调整,只有调整img才有效*/

@media only screen and (min-width:0px) and (max-width:767px) {
  .image-location{
    width: calc(100% - 10px);
    margin:10px 5px;
  }

  .review h1{
    font-size: 20px;
    padding-bottom: 20px;
  }


}





</style>